// extracted by mini-css-extract-plugin
export var content = "index-module--content--ZXwgT";
export var hasMaxWidth200 = "index-module--has-max-width-200--1ZoSW";
export var hasMaxWidth600 = "index-module--has-max-width-600--22FJp";
export var eyecatch = "index-module--eyecatch--2U9N1";
export var banner = "index-module--banner--3ePnd";
export var banner__label = "index-module--banner__label--1aMXA";
export var banner__cover = "index-module--banner__cover--3SSoB";
export var banner__image = "index-module--banner__image--klS2F";
export var ourfutures = "index-module--ourfutures--1rjdt";
export var ourfutures__image1 = "index-module--ourfutures__image1--1kOmW";